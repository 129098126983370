/* ----------------------------------------------
   HTML ELEMENTS
------------------------------------------------- */
body {
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  color:$text-color;
  background-color:$body-bg;
}
body.landing-screen {
  background-color: $body-bg-alt;
}
a {
  outline: none!important;
  text-decoration:underline;
}
a img {
  border: 0
}
img,
object,
embed {
  max-width: 100%;
}
img {
  height: auto;
  -ms-interpolation-mode:bicubic;
}
.img-rounded {
  border-radius: $border-radius-base;
}
* {
  outline:none;
}
hr {
  margin-top:12px;
  margin-bottom:12px;
}
dd {
  margin-bottom:2px;
}

/* ----------------------------------------------
   TABLES
------------------------------------------------- */
table {
  margin:0 0 10px;
}
@media (min-width: $screen-sm) {
  .table {
	border: 1px solid $table-border-color;
  }
}
.data-panel .table {
  border: none;
}
.table .table {
  background-color:transparent;
}
.table-nowrap td, .table-nowrap th {
  white-space:nowrap;
}
.nowrap {
  white-space:nowrap;
}
.nostretch {
  width:1%;
}

/* Table Condensed XS */
.table-condensed-xs {
  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
	padding: 2px 3px;
  }
}

/* Rowlink */
.table.table-rowlink,
.table .table-rowlink {
  td:not(.rowlink-skip) {
	cursor: pointer;
  }
}
/* ----------------------------------------------
   TYPOGRAPHY
------------------------------------------------- */
h1, h2, h3, h4, h5, h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  color: $headings-color;
}
.section-title {
  font-size:14px;
  font-weight: 500;
  margin:0;
  padding:6.5px 10px;
  border-radius:$border-radius-base;
}
.section-title.bg-info {
  background-color: $state-default-bg!important;
}

.font-size-smaller {
  font-size: $font-size-smaller;
}

.text-muted {
  color: $text-muted!important;
}
.text-primary {
  color: $brand-primary;
}
.text-success {
  color: #4DA25B;
}
.text-info {
  color: $brand-info;
}
.text-warning {
  color: #FFB400;
}
.text-danger {
  color: #A71304;
}

.text-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

.text-strike-through {
  text-decoration: line-through;
}

.bg-default {
  background-color: $state-default-bg;
}

.mrgn-top {
  margin-top:10px;
}
.mrgn-btm {
  margin-bottom:10px;
}
.mrgn-0 {
  margin:0!important;
}
.pdng-0 {
  padding:0!important;
}

.cursor-pointer {
  cursor:pointer;
}
.bordered-t {
  border-top: 1px solid $table-border-color;
}
.bordered-b {
  border-bottom: 1px solid $table-border-color;
}
.bordered-l {
  border-left: 1px solid $table-border-color;
}
.bordered-r {
  border-right: 1px solid $table-border-color;
}
@media (min-width: $screen-sm) {
  dt {
	margin-bottom:2px;
  }
  .dl-horizontal dt {
	width: 110px;
	text-align: left;
  }
  .dl-horizontal dd {
	margin-left: 120px;
  }
}

/* Lists */
.list-inline-md {
  @extend .list-inline;
  margin-left: -10px;

  > li {
	padding-left: 10px;
	padding-right: 10px;
  }
}

@media print {
  .table-striped > tbody > tr:nth-of-type(odd) > td {
	background-color: $grey-dd!important;
	-webkit-print-color-adjust: exact;
  }
}
