@use 'ag-grid-community/styles' as ag;

/* Contextual Backgrounds */
.bg-primary {
    background-color: $brand-primary!important;
}
.bg-success {
    background-color: $state-success-bg!important;
}
.bg-info {
    background-color: $state-info-bg!important;
}
.bg-warning {
    background-color: $state-warning-bg!important;
}
.bg-danger {
    background-color: $state-danger-bg!important;
}

/* Buttons */
.e-btn + .e-btn,
.e-btn + rev-button,
.e-btn + rev-button-menu,
rev-button + rev-button,
rev-button + .e-btn,
rev-button + rev-button-menu,
rev-button-menu + rev-button-menu,
rev-button-menu + rev-button,
rev-button-menu + .e-btn {
  margin-left: 0.5em;
}

rev-button, rev-button-menu {
  display: inline-block;
}

rev-button.e-btn-block {
  width: 100%;
  .e-btn {
	display: block;
	width: 100%;
  }
}

.e-btn-group, .e-split-btn-wrapper {
    .e-btn + .e-btn {
        margin-left: 0;
    }
}

.e-btn.e-active:disabled, .e-css.e-btn.e-active:disabled {
    background-color: $btn-active-bgcolor;
}

.e-split-btn-wrapper .e-dropdown-btn {
  margin-left: -1px!important;
}

/* Tabs */
.e-tab {
    .e-tab-header {
        margin-bottom: 10px;

		.e-toolbar-items {
			height: auto;
		}

		.e-toolbar-item {
		  border: none;
		  border-bottom: 3px solid transparent;

		  .e-tab-wrap:hover {
			background: none;
		  }

		  &.e-active {
			border:none;
			border-radius: 0;
		  }

		  .e-tab-text {
			align-items: center;
			align-content: center;
		  }

		  .e-tab-icon {
			font-size: $tab-nrml-font-size;
			min-width: 15px!important;
			width: 15px!important;
			height: 15px!important;
			margin-top: 1px;

			&:before {
			  font-size: $tab-nrml-font-size;
			  top: 0!important;
			  left: 0!important;
			}
		  }

		  .e-icon-left + .e-tab-text {
			margin: 0 0 0 5px;
		  }
		}
	  	&:not(.e-vertical) .e-toolbar-item.e-active {
		  border-bottom: 3px solid $tab-active-text-color;
		}

        .e-toolbar-item:not(.e-separator) {
		  margin: 0!important;
        }

        &:not(.e-vertical) .e-toolbar-item .e-tab-wrap {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .e-toolbar-item-non-closable {
            .e-close-icon {
                display: none!important;
            }
        }

		.e-hor-nav, .e-hor-nav:not(.e-expended-nav) {
		  	.e-popup-down-icon {
			  border: none!important;
			}
		}

		.e-scroll-nav {
			min-height: $tab-nrml-height!important;
			height: $tab-nrml-height!important;
		}
	  	.e-toolbar-items.e-hscroll .e-scroll-nav,
		.e-scroll-nav .e-nav-arrow {
		  	width: 30px;
		}
    }

    /* Section Tabs */
    &.section-tabs {
        border-bottom: 1px solid $grey-dd;

        .e-tab-header {
            margin-bottom: -1px;

            .e-toolbar-item.e-active {
                background-color: #fff;
            }

            &:not(.e-vertical) .e-toolbar-item {
                border-bottom: 1px solid $grey-dd;
            }

            &:not(.e-vertical) .e-toolbar-item.e-active {
                border-bottom-color: #fff;
            }
        }
    }

    /* Responsive Tab Dropdown */
    .e-toolbar-pop .e-toolbar-item {
        cursor: pointer;
    }

  	&.margin-bottom-0 {
	  .e-tab-header {
		margin-bottom: 0;
	  }
	}
}
.e-hscroll:not(.e-scroll-device) {
  padding: 0 30px;
}
.mat-tab-nav-bar {
  border-bottom: 1px solid $grey-dd;
  display: block;
  height: $tab-nrml-height;
  min-height: $tab-nrml-height;
  vertical-align: middle;
  white-space: nowrap;
  margin-bottom: 10px;

  .mat-tab-link {
	align-content: center;
	align-items: center;
	color: $tab-text-color;
	cursor: pointer;
	display: inline-flex;
	font-family: $font-family;
	font-size: $tab-nrml-font-size;
	font-weight: $tab-font-weight;
	height: $tab-nrml-height;
	margin: 0 1px 0 0;
	min-height: 0;
	padding: $tab-nrml-wrap-padding;
	text-decoration: none;
	vertical-align: middle;
	width: auto;
	border-bottom: 3px solid transparent!important;

	&:hover {
	  background: none;
	  color: $tab-hover-text-color;
	}

	&.active {
	  background: none!important;
	  border-bottom: 3px solid $tab-active-text-color!important;
	  color: $tab-active-text-color;

	  &:hover {
		color: $tab-active-text-color;
	  }

	  .mat-tab-link-icon {
		color: inherit!important;
	  }
	}

	&.disabled {
		background: none;
		cursor: default!important;
		pointer-events: none;
		filter: alpha(Opacity=35);
		opacity: 0.35;
	}

	.mat-tab-link-icon {
	  display: inline-flex;
	  font-size: $tab-nrml-font-size;
	  height: 15px;
	  min-width: 15px;
	  width: 15px;
	  margin-top: 1px;
	}

	.mat-tab-link-text {
	  align-items: center;
	  align-content: center;
	  margin: 0 0 0 5px;
	}

	.badge {
	  background: $badge-bg;
	  color: #fff;
	}
  }
}

/* Panel Nav Tabs */
.panel.with-nav-tabs > .panel-heading > .e-tab {
    .e-tab-header {
        margin-bottom: -1px;

        &:not(.e-vertical)::before {
            border-width: 0;
        }

        .e-toolbar-items {

            .e-toolbar-item {
                border-top: none;
                border-radius: 0;

                &.e-active {
                    border: 1px solid #ccc;
                    border-bottom-color: $grey-f9;

                    .e-tab-wrap {
                        background-color: $grey-f9;
                    }
                }

                &:not(.e-active) {
                    .e-tab-wrap {
                        border-bottom: 1px solid #ccc;
                    }
                }

                .e-tab-wrap {
                    border-radius: 0;
                }
            }

            .e-indicator.e-ignore + {

                .e-toolbar-item {
                    border-top-left-radius: 3px;
                    border-left: none;

                    .e-tab-wrap {
                        border-top-left-radius: 3px;
                    }
                }
            }
        }
    }

    .e-tab-header, .e-toolbar-items, .e-toolbar-item:not(.e-separator), .e-tab-wrap, .e-text-wrap {
        min-height: 38px;
    }
}
.panel.with-nav-tabs > .panel-heading > .mat-tab-nav-bar {
  border-bottom: none;
  margin-bottom: -1px;
  height: 38px;
  min-height: 38px;

  .mat-tab-link {
	border-bottom: 1px solid #ccc;
	height: 38px;

	&:hover {
	  background: $grey-lighter;
	}

	&.active {
	  background: $grey-f9!important;
	  border-color: #ccc;
	  border-bottom-color: $grey-f9;
	  border-top: none;
	}

	&:first-of-type {
	  border-top-left-radius: 3px;
	  border-left: none;
	}
  }
}

/* Anchors */
a.anchor {
    scroll-margin-top: 40px; /* height of app header */
}

/* Dropdowns */
.e-dropdown-popup {
  z-index: 1035;

  ul {
	font-size: $font-size;

	.e-item {
	  padding-left: $ddl-list-padding-right!important;
	  padding-right: $ddl-list-padding-right!important;

	  &:hover {
		background-color: $ddl-list-hover-bg-color !important;
	  }
	}
  }
}

/* Display Helper Classes */
.display-block {
    display: block!important;
}
.display-flex {
    display: flex!important;
}
.display-grid {
    display: grid!important;
}
.display-inline {
    display: inline!important;
}
.display-inline-block {
    display: inline-block!important;
}
.display-inline-flex {
    display: inline-flex!important;
}
.display-inline-grid {
    display: inline-grid!important;
}
.display-inline-table {
    display: inline-table!important;
}
.display-list-item {
    display: list-item!important;
}
.display-table {
    display: table!important;
}
.display-table-caption {
    display: table-caption!important;
}
.display-table-column-group {
    display: table-column-group!important;
}
.display-table-header-group {
    display: table-header-group!important;
}
.display-table-footer-group {
    display: table-footer-group!important;
}
.display-table-row-group {
    display: table-row-group!important;
}
.display-table-cell {
    display: table-cell!important;
}
.display-table-column {
    display: table-column!important;
}
.display-table-row {
    display: table-row!important;
}
.display-none {
    display: none!important;
}

/* Vertical Alignment Helper Classes */
.vertical-align-baseline {
    vertical-align: baseline!important;
}
.vertical-align-sub {
    vertical-align: sub!important;
}
.vertical-align-super {
    vertical-align: super!important;
}
.vertical-align-top {
    vertical-align: top!important;
}
.vertical-align-text-top {
    vertical-align: text-top!important;
}
.vertical-align-middle {
    vertical-align: middle!important;
}
.vertical-align-bottom {
    vertical-align: bottom!important;
}
.vertical-align-text-bottom {
    vertical-align: text-bottom!important;
}

/* Overflow Helper Classes */
.overflow-auto {
    overflow: auto!important;
}
.overflow-hidden {
    overflow: hidden!important;
}
.overflow-inherit {
    overflow: inherit!important;
}
.overflow-initial {
    overflow: initial!important;
}
.overflow-scroll {
    overflow: scroll!important;
}
.overflow-visible {
    overflow: visible!important;
}
.overflow-x-auto {
    overflow-x: auto!important;
}
.overflow-x-hidden {
    overflow-x: hidden!important;
}
.overflow-x-inherit {
    overflow-x: inherit!important;
}
.overflow-x-initial {
    overflow-x: initial!important;
}
.overflow-x-scroll {
    overflow-x: scroll!important;
}
.overflow-x-visible {
    overflow-x: visible!important;
}
.overflow-y-auto {
    overflow-y: auto!important;
}
.overflow-y-hidden {
    overflow-y: hidden!important;
}
.overflow-y-inherit {
    overflow-y: inherit!important;
}
.overflow-y-initial {
    overflow-y: initial!important;
}
.overflow-y-scroll {
    overflow-y: scroll!important;
}
.overflow-y-visible {
    overflow-y: visible!important;
}

/* Whitespace Helper Classes */
.white-space-normal {
  white-space: normal!important;
}
.white-space-nowrap {
  white-space: nowrap!important;
}
.white-space-pre {
  white-space: pre!important;
}
.white-space-pre-wrap {
  white-space: pre-wrap!important;
}
.white-space-pre-line {
  white-space: pre-line!important;
}

/* Cursor & Pointer Events Helper Classes */
.cursor-alias {cursor: alias!important;}
.cursor-all-scroll {cursor: all-scroll!important;}
.cursor-auto {cursor: auto!important;}
.cursor-cell {cursor: cell!important;}
.cursor-context-menu {cursor: context-menu!important;}
.cursor-col-resize {cursor: col-resize!important;}
.cursor-copy {cursor: copy!important;}
.cursor-crosshair {cursor: crosshair!important;}
.cursor-default {cursor: default!important;}
.cursor-e-resize {cursor: e-resize!important;}
.cursor-ew-resize {cursor: ew-resize!important;}
.cursor-grab {cursor: grab!important;}
.cursor-grabbing {cursor: grabbing!important;}
.cursor-help {cursor: help!important;}
.cursor-inherit {cursor: inherit!important;}
.cursor-initial {cursor: initial!important;}
.cursor-move {cursor: move!important;}
.cursor-n-resize {cursor: n-resize!important;}
.cursor-ne-resize {cursor: ne-resize!important;}
.cursor-nesw-resize {cursor: nesw-resize!important;}
.cursor-ns-resize {cursor: ns-resize!important;}
.cursor-nw-resize {cursor: nw-resize!important;}
.cursor-nwse-resize {cursor: nwse-resize!important;}
.cursor-no-drop {cursor: no-drop!important;}
.cursor-none {cursor: none!important;}
.cursor-not-allowed {cursor: not-allowed!important;}
.cursor-pointer {cursor: pointer!important;}
.cursor-progress {cursor: progress!important;}
.cursor-row-resize {cursor: row-resize!important;}
.cursor-s-resize {cursor: s-resize!important;}
.cursor-se-resize {cursor: se-resize!important;}
.cursor-sw-resize {cursor: sw-resize!important;}
.cursor-text {cursor: text!important;}
.cursor-w-resize {cursor: w-resize!important;}
.cursor-wait {cursor: wait!important;}
.cursor-zoom-in {cursor: zoom-in!important;}
.cursor-zoom-out {cursor: zoom-out!important;}

.pointer-events-auto {pointer-events: auto!important;}
.pointer-events-inherit {pointer-events: inherit!important;}
.pointer-events-initial {pointer-events: initial!important;}
.pointer-events-none {pointer-events: none!important;}

/* Border Collapse Helper Classes */
.border-collapse-collapse {
    border-collapse: collapse;
}
.border-collapse-inherit {
    border-collapse: inherit;
}
.border-collapse-initial {
    border-collapse: initial;
}
.border-collapse-separate {
    border-collapse: separate;
}

/* Padding/Margin Helper Classes */
.padding-0 { padding: 0!important; }
.padding-xs { padding: .25em!important; }
.padding-sm { padding: .5em!important; }
.padding-md { padding: 1em!important; }
.padding-lg { padding: 1.5em!important; }
.padding-xl { padding: 3em!important; }
.padding-x-0 { padding-top: 0!important; padding-bottom: 0!important; }
.padding-x-xs { padding: .25em 0!important; }
.padding-x-sm { padding: .5em 0!important; }
.padding-x-md { padding: 1em 0!important; }
.padding-x-lg { padding: 1.5em 0!important; }
.padding-x-xl { padding: 3em 0!important; }
.padding-y-0 { padding-left: 0!important; padding-right: 0!important; }
.padding-y-xs { padding: 0 .25em!important; }
.padding-y-sm { padding: 0 .5em!important; }
.padding-y-md { padding: 0 1em!important; }
.padding-y-lg { padding: 0 1.5em!important; }
.padding-y-xl { padding: 0 3em!important; }
.padding-top-0 { padding-top: 0!important; }
.padding-top-xs { padding-top: .25em!important; }
.padding-top-sm { padding-top: .5em!important; }
.padding-top-md { padding-top: 1em!important; }
.padding-top-lg { padding-top: 1.5em!important; }
.padding-top-xl { padding-top: 3em!important; }
.padding-right-0 { padding-right: 0!important; }
.padding-right-xs { padding-right: .25em!important; }
.padding-right-sm { padding-right: .5em!important; }
.padding-right-md { padding-right: 1em!important; }
.padding-right-lg { padding-right: 1.5em!important; }
.padding-right-xl { padding-right: 3em!important; }
.padding-bottom-0 { padding-bottom: 0!important; }
.padding-bottom-xs { padding-bottom: .25em!important; }
.padding-bottom-sm { padding-bottom: .5em!important; }
.padding-bottom-md { padding-bottom: 1em!important; }
.padding-bottom-lg { padding-bottom: 1.5em!important; }
.padding-bottom-xl { padding-bottom: 3em!important; }
.padding-left-0 { padding-left: 0!important; }
.padding-left-xs { padding-left: .25em!important; }
.padding-left-sm { padding-left: .5em!important; }
.padding-left-md { padding-left: 1em!important; }
.padding-left-lg { padding-left: 1.5em!important; }
.padding-left-xl { padding-left: 3em!important; }
.margin-0 { margin: 0!important; }
.margin-xs { margin: .25em!important; }
.margin-sm { margin: .5em!important; }
.margin-md { margin: 1em!important; }
.margin-lg { margin: 1.5em!important; }
.margin-xl { margin: 3em!important; }
.margin-x-0 { margin-top: 0!important; margin-bottom: 0!important; }
.margin-x-xs { margin: .25em 0!important; }
.margin-x-sm { margin: .5em 0!important; }
.margin-x-md { margin: 1em 0!important; }
.margin-x-lg { margin: 1.5em 0!important; }
.margin-x-xl { margin: 3em 0!important; }
.margin-y-0 { margin-left: 0!important; margin-right: 0!important; }
.margin-y-xs { margin: 0 .25em!important; }
.margin-y-sm { margin: 0 .5em!important; }
.margin-y-md { margin: 0 1em!important; }
.margin-y-lg { margin: 0 1.5em!important; }
.margin-y-xl { margin: 0 3em!important; }
.margin-top-0 { margin-top: 0!important; }
.margin-top-xs { margin-top: .25em!important; }
.margin-top-sm { margin-top: .5em!important; }
.margin-top-md { margin-top: 1em!important; }
.margin-top-lg { margin-top: 1.5em!important; }
.margin-top-xl { margin-top: 3em!important; }
.margin-right-0 { margin-right: 0!important; }
.margin-right-xs { margin-right: .25em!important; }
.margin-right-sm { margin-right: .5em!important; }
.margin-right-md { margin-right: 1em!important; }
.margin-right-lg { margin-right: 1.5em!important; }
.margin-right-xl { margin-right: 3em!important; }
.margin-bottom-0 { margin-bottom: 0!important; }
.margin-bottom-xs { margin-bottom: .25em!important; }
.margin-bottom-sm { margin-bottom: .5em!important; }
.margin-bottom-md { margin-bottom: 1em!important; }
.margin-bottom-lg { margin-bottom: 1.5em!important; }
.margin-bottom-xl { margin-bottom: 3em!important; }
.margin-left-0 { margin-left: 0!important; }
.margin-left-xs { margin-left: .25em!important; }
.margin-left-sm { margin-left: .5em!important; }
.margin-left-md { margin-left: 1em!important; }
.margin-left-lg { margin-left: 1.5em!important; }
.margin-left-xl { margin-left: 3em!important; }

/* Layout */
html, body {
    min-height: 100vh;
    overflow: auto;
}

@media (min-width: 768px) {
    #wrap, .wrap {
        padding-right: 210px;
    }
    #sidebar, .sidebar {
        float: left;
        margin-right: 10px;
        width: 200px;
    }
    #main, .main {
        float: right;
        margin: 0 -210px 0 auto;
        width: 100%;
    }
}

/* Flexbox Layout */
.flex-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.flex-fixed-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.flex-flexible-column {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  min-width: 0;
}

.w0 {
    width: 0px !important;
}
.w25 {
    width: 25px !important;
}
.w50 {
    width: 50px !important;
}
.w75 {
    width: 75px !important;
}
.w100 {
    width: 100px !important;
}
.w125 {
    width: 125px !important;
}
.w150 {
    width: 150px !important;
}
.w175 {
    width: 175px !important;
}
.w200 {
    width: 200px !important;
}
.w250 {
    width: 250px !important;
}
.w300 {
    width: 300px !important;
}
.w350 {
    width: 350px !important;
}
.w400 {
    width: 400px !important;
}
.w450 {
    width: 450px !important;
}
.w500 {
    width: 500px !important;
}
.wAuto {
    width: auto !important;
}
.wFull {
    width: 100% !important;
}

.mw25 {
  max-width: 25px !important;
}
.mw35 {
  max-width: 35px !important;
}
.mw50 {
  max-width: 50px !important;
}
.mw75 {
  max-width: 75px !important;
}
.mw100 {
  max-width: 100px !important;
}
.mw125 {
  max-width: 125px !important;
}
.mw150 {
  max-width: 150px !important;
}
.mw175 {
  max-width: 175px !important;
}
.mw200 {
  max-width: 200px !important;
}
.mw250 {
  max-width: 250px !important;
}
.mw300 {
  max-width: 300px !important;
}
.mw350 {
  max-width: 350px !important;
}
.mw400 {
  max-width: 400px !important;
}
.mw450 {
  max-width: 450px !important;
}
.mw500 {
  max-width: 500px !important;
}
.minw200 {
  min-width: 200px !important;
}

.h0 {
    height: 0px !important;
}
.h25 {
    height: 25px !important;
}
.h50 {
    height: 50px !important;
}
.h75 {
    height: 75px !important;
}
.h100 {
    height: 100px !important;
}
.h125 {
    height: 125px !important;
}
.h150 {
    height: 150px !important;
}
.h175 {
    height: 175px !important;
}
.h200 {
    height: 200px !important;
}
.h250 {
    height: 250px !important;
}
.h300 {
    height: 300px !important;
}
.h315 {
    height: 315px !important;
}
.h350 {
    height: 350px !important;
}
.h385 {
    height: 385px !important;
}
.h400 {
    height: 400px !important;
}
.h450 {
    height: 450px !important;
}
.h500 {
    height: 500px !important;
}
.hAuto {
    height: auto !important;
}
.hFull {
    height: 100% !important;
}

.mh25 {
  max-height: 25px !important;
}
.mh35 {
  max-height: 35px !important;
}
.mh50 {
  max-height: 50px !important;
}
.mh75 {
  max-height: 75px !important;
}
.mh100 {
  max-height: 100px !important;
}
.mh125 {
  max-height: 125px !important;
}
.mh150 {
  max-height: 150px !important;
}
.mh175 {
  max-height: 175px !important;
}
.mh200 {
  max-height: 200px !important;
}
.mh250 {
  max-height: 250px !important;
}
.mh300 {
  max-height: 300px !important;
}
.mh350 {
  max-height: 350px !important;
}
.mh400 {
  max-height: 400px !important;
}
.mh450 {
  max-height: 450px !important;
}
.mh500 {
  max-height: 500px !important;
}

/* Textarea Row Height Helper Classes */
textarea.e-input, .e-input-group textarea.e-input, .e-input-group.e-control-wrapper textarea.e-input {
    &.h-rows-1 {
        height: $textarea-row-height + 12px;
    }
    &.h-rows-2 {
        height: $textarea-row-height * 2 + 12px;
    }
    &.h-rows-3 {
        height: $textarea-row-height * 3 + 12px;
    }
    &.h-rows-4 {
        height: $textarea-row-height * 4 + 12px;
    }
    &.h-rows-5 {
        height: $textarea-row-height * 5 + 12px;
    }
    &.h-rows-6 {
        height: $textarea-row-height * 6 + 12px;
    }
    &.h-rows-10 {
        height: $textarea-row-height * 10 + 12px;
    }
    &.h-rows-12 {
        height: $textarea-row-height * 12 + 12px;
    }
	&.h-rows-15 {
	  height: $textarea-row-height * 15 + 12px;
	}
    &.h-rows-20 {
        height: $textarea-row-height * 20 + 12px;
    }
    &.h-rows-30 {
    	height: $textarea-row-height * 30 + 12px;
    }
}

/* Toast */
.e-toast-container {
    z-index: 3000 !important;
}

/* Tooltips */
.e-tooltip-wrap {
  .e-tip-content {
	h1, h2, h3, h4, h5, h6, small, .small {
	  color: $text-color!important;
	}
	table {
	  background: none!important;
	}
  }
}
.tippy-box {
  .tippy-content {
	font-size: $font-size;
	text-align: left;

	h1, h2, h3, h4, h5, h6, small, .small {
	  color: $text-color!important;
	}
	table {
	  background: none!important;
	}
  }
}

.rev-custom-tooltip-content {
	background-color: #fff;
  	color: #181d1f;
  	padding: 5px;
  	border: solid 1px #ddd;
  	border-radius: 3px;
  	box-shadow: 0 1px 4px 1px rgba(186, 191, 199, .4);
}

.tooltip {
  font-size: $font-size-base;
  .tooltip-inner {
	border: 1px solid rgba(0,8,16,.15);
	padding: 5px 8px;
  }
  .tooltip-arrow {
	&:after {
	  position: absolute;
	  display: block;
	  width: 0;
	  height: 0;
	  border-color: transparent;
	  border-style: solid;
	}
  }
  &.top .tooltip-arrow {
	bottom: -2px!important;
	border-width: 8px 8px 0;
	border-top-color: rgba(0,8,16,.15);
	margin-left: -8px;
	&:after {
	  bottom: 1px;
	  margin-left: -7px;
	  content: " ";
	  border-top-color: $tooltip-bg;
	  border-width: 7px 7px 0;
	}
  }
  &.bottom .tooltip-arrow {
	top: -2px!important;
	border-width: 0 8px 8px;
	border-bottom-color: rgba(0,8,16,.15);
	margin-left: -8px;
	&:after {
	  top: 1px;
	  margin-left: -7px;
	  content: " ";
	  border-bottom-color: $tooltip-bg;
	  border-width: 0 7px 7px;
	}
  }
}

/* Forms */
label.required:after, label span.text-danger {
  color: $state-danger-text;
  font-size: 20px;
  line-height: 1;
  vertical-align: top;
}
label.required:after {
  content: "*";
  margin-left: 2px;
  white-space: nowrap;
}

.e-input-group, .e-input-group.e-control-wrapper {
  input.e-input {
	height: $input-child-min-height;
  }
}

.e-multiselect {
	min-width: 64px;
    .e-multi-select-wrapper {
        min-height: $input-child-min-height;
		min-width: 60px;
        height: $input-child-min-height;

        input[type='text'] {
            font-size: inherit;
		  	height: $input-child-min-height!important;
        }

        .e-chips-collection {
            margin-left: 2px;

            .e-chips {
                height: 28px;
            }
        }

        .e-delim-values {
            padding-left: $input-text-indent;
            line-height: $input-child-min-height;
        }

        .e-chips-close.e-close-hooker {
            right: 20px;
            color: $grey-light;
            width: 32px;
            height: $input-child-min-height;
            margin-top: 0;
            top: 0;
            display: none!important;
            flex-direction: column;
            justify-content: center;

            &:hover {
                color: $grey-dark;
            }

            &::before {
                content:'\e96c';
                font-size: 12px;
                left: initial;
                top: initial;
                text-align: center;
                vertical-align: middle;
            }
        }
    }

	.e-multi-select-wrapper:hover, .e-multi-select-wrapper:focus {
		.e-chips-close.e-close-hooker {
			display: flex !important;
		}
	}

    .e-input-group-icon.e-ddl-icon {
        height: $input-child-min-height;
        width: 20px;
        border-left: none;
	  	border-right: none;

        &:hover {
            background: none;
        }
    }
}
.e-popup.e-multi-select-list-wrapper.e-checkbox .e-list-item.e-active.e-item-focus {
    color: $brand-primary-font;

    &:hover {
        background-color: $brand-primary;
    }
}

.e-popup-auto-width {
	width: auto!important;
	max-width: 300px;
}

.e-dropdownbase .e-list-item {
    line-height: inherit;
    min-height: inherit;
    padding: 5px $ddl-list-padding-right;
    text-indent: 0px;
    white-space: normal;
}

.mat-select {
  background-color: $grey-white;
  border: 1px solid $input-box-border-color;
  border-radius:$border-radius-base;
  font-size: $font-size;
  font-family: $font-family;
  font-weight: normal;
  height: $input-normal-height;

  &:focus {
    border-color: $input-active-border-color!important;
    -webkit-box-shadow: $input-border-shadow!important;
    box-shadow: $input-border-shadow!important;
  }

  &.disabled,
  &.disabled .mat-select-value,
  &.disabled .e-input-group,
  &.disabled .e-multi-select-wrapper .e-delim-values {
    background: $grey-f9 !important;
    color: $grey !important;
  }

  &.disabled,
  &.disabled .mat-select-value,
  &.disabled .e-input-group,
  &.disabled .mat-select-trigger,
  &.disabled .e-multi-select-wrapper,
  &.disabled .e-delim-view.e-delim-values,
  &.disabled .e-delim-view.e-delim-values .e-remain {
    cursor: no-drop !important;
  }

  &.disabled .mat-select-arrow {
    color: $grey-99!important;
  }

  .mat-select-value {
    height: $input-normal-height - 2px;
  }
}

.mat-select-arrow {
  color: $grey-dark;
}

.mat-select-placeholder {
	display: block;
	height: $input-normal-height - 2px;
	line-height: $input-normal-height - 2px;
	padding-left: $input-text-indent;
}

.mat-select-panel-normal {
  margin-top: $input-normal-height;
}

.mat-select-panel-extra-small {
	margin-top: 22px;
}

.mat-select-panel, .mat-menu-panel {
  border: 1px solid $grey-cc;

  &:not([class*=mat-elevation-z]) {
    box-shadow: 0 6px 12px $ddl-list-box-shadow-color;
  }

  .e-ddl {
    position: relative !important;
    border: none;
    margin-top: 0px;
  }

  .e-content.e-dropdownbase {
    max-height: 210px;
  }
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

.additional-selection {
  opacity: 0.75;
  font-size: 0.75em;
}

.multiselect-close {
  display: flex !important;
}

.hide-border {
  .e-input-group.e-extra-small {
	border-width: 0 !important;
  }
}

.mat-select-trigger {
  height: 100% !important;

  .e-multiselect .e-multi-select-wrapper .e-chips-close {
	right: 2px !important;
  }
}

.mat-pseudo-checkbox.mat-option-pseudo-checkbox {
  background: #fff !important;
  border-color: $grey-cc !important;
  border-radius: 3px;
  border-width: 1px;
  height: 20px;
  width: 20px;
}

.mat-checkbox .mat-option .mat-accent .mat-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox .mat-option-pseudo-checkbox {
  background: #fff !important;
  border-color: $grey-cc !important;
  color: #555 !important;
}

.mat-pseudo-checkbox::after {
  color: #555 !important;
}
.mat-pseudo-checkbox-checked::after {
  width: 9px!important;
  height: 3px!important;
  border-bottom-width: 3px!important;
  border-left-width: 3px!important;
}

.mat-option, .mat-menu-item {
  font-family: $font-family!important;
  font-size: $ddl-list-font-size!important;
  color: $ddl-list-default-font-color;
  line-height: $ddl-list-line-height!important;
  height: $ddl-list-line-height!important;
  padding-left: $ddl-list-padding-right!important;
  padding-right: $ddl-list-padding-right!important;

  &.mat-active {
	background-color: $ddl-list-hover-bg-color!important;

	&.mat-selected {
	  background-color: $input-accent !important;

	  .mat-option-text {
		color: #fff;
	  }
	}
  }
}
.mat-menu-panel {
  box-shadow: 0 6px 12px $ddl-list-box-shadow-color!important;
}
.mat-menu-content {
  padding: 3px 0 !important;
}
.mat-menu-item:hover {
  background-color: $ddl-list-hover-bg-color !important;
}
.mat-divider {
  margin: 3px 0!important;
}

.e-multi-select-list-wrapper {
  width: auto!important;

  .e-list-item {
    min-width: 75px;
  }

  .mat-option {
    line-height: 33px!important;
    height: 33px!important;
  }

  .mat-checkbox {
    display: block;
	.mat-checkbox-inner-container {
	  margin-left: -9px;
	}
  }
}

.mat-checkbox {
  .mat-checkbox-layout {
	display: block;
	font-weight: normal;
  }
  .mat-checkbox-inner-container {
	height: 20px;
	width: 20px;
  }
  .mat-checkbox-frame, .mat-checkbox-background {
	border-color: $grey-cc !important;
	border-width: 1px !important;
	border-radius: 3px;
  }
  .mat-checkbox-label {
	margin-left: -4px;
  }
  label {
	margin-bottom: 0;
  }
  .mat-checkbox-ripple {
	display: none!important;
  }
}

.mat-checkbox-background {
  background-color: #fff !important;
  border: 2px solid #848181 !important;

  .mat-checkbox-checkmark-path {
	stroke: #000 !important;
	stroke-width: 4px !important;
  }
}

@media (min-width: 768px) {
    .form-inline {
        .form-group {
            label:not(.btn):not(.e-btn) {
                margin-right: 0.5em;
            }

            label + [class*='e-'] {
                display: inline-block;
                vertical-align: middle;
                width: auto;
            }

            .e-datepicker {
                display: inline-block;
                width: 185px!important;
            }
        }
    }
}

.e-input-group {
    .e-input-group {
        border: none!important;
    }

    .e-control {
        min-width: 0;
        width: 100%;
    }

    .e-input-group-icon + .e-input-group-icon {
        border-right-width: 1px;
    }

	.e-input-group-icon:last-child {
	  border-right-width: 0!important;
	}
}

textarea.e-input {
    padding: 6px 8px!important;
}

.e-checkbox-wrapper, .e-css.e-checkbox-wrapper, .e-radio + label {
    .e-label {
        font-size: $font-size;
        font-weight: 500;
    }
}

.form-inline {
    .e-checkbox-wrapper, .e-css.e-checkbox-wrapper {
        label {
            margin-bottom: 5px;
        }
    }
}

.e-input {
    &.ng-invalid {
        border-color: $input-box-border-color!important;
        box-shadow: none!important;

        &:focus {
            border-color: $input-active-border-color!important;
            -webkit-box-shadow: $input-border-shadow!important;
            box-shadow: $input-border-shadow!important;
        }
    }
    &.e-disabled, &[disabled] {
        background: $input-disabled-group-bg-color!important;
    }
}

form.submitted {
    .e-input {
        &.ng-invalid {
            border-color: $input-error-color!important;

            &:focus {
                border-color: $input-error-color!important;
                -webkit-box-shadow: $input-error-border-shadow!important;
                box-shadow: $input-error-border-shadow!important;
            }
        }
    }

    .ng-invalid > .e-input-group,
    pms-constant-dropdown.ng-invalid > .e-dropdownlist > .e-input-group,
	pms-phone-number-input.ng-invalid > div > .e-input {
        border-color: $input-error-color!important;

        &.e-input-focus {
            border-color: $input-error-color!important;
            -webkit-box-shadow: $input-error-border-shadow!important;
            box-shadow: $input-error-border-shadow!important;
        }
    }
}

.e-dropdownlist, .e-multiselect, pms-number-stepper {
  .e-input-group-icon {
	min-width: 20px!important;
  }
}

.e-input, .e-input-group, .e-input-group.e-control-wrapper {
    &.e-extra-small {
        margin-bottom: 0;
    }
}
input.e-input.e-extra-small,
textarea.e-input.e-extra-small,
.e-extra-small input.e-input,
.e-extra-small textarea.e-input,
.e-input-group.e-extra-small,
.e-extra-small .e-input-group,
.e-input-group.e-control-wrapper.e-extra-small,
.e-extra-small .e-input-group.e-control-wrapper,
.e-input-group.e-extra-small.e-disabled,
.e-extra-small .e-input-group.e-disabled,
.e-input-group.e-control-wrapper.e-extra-small.e-disabled,
.e-extra-small .e-input-group.e-control-wrapper.e-disabled {
    font-size: 12px;
}
input.e-input.e-extra-small,
.e-extra-small input.e-input,
.e-input-group.e-extra-small input.e-input,
.e-input-group.e-control-wrapper.e-extra-small input.e-input,
.e-float-input.e-extra-small input,
.e-float-input.e-control-wrapper input.e-extra-small,
.e-float-input.e-extra-small input,
.e-float-input.e-control-wrapper input.e-extra-small,
.e-input-group input.e-input.e-extra-small,
.e-input-group.e-control-wrapper input.e-input.e-extra-small,
.e-extra-small .e-float-input input,
.e-extra-small .e-float-input.e-control-wrapper input,
.e-extra-small .e-input-group input.e-input,
.e-extra-small .e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-extra-small input.e-input:focus,
.e-input-group.e-control-wrapper.e-extra-small input.e-input:focus,
.e-float-input.e-extra-small input:focus,
.e-float-input.e-control-wrapper.e-extra-small input:focus,
.e-extra-small .e-input-group.e-control-wrapper input.e-input:focus,
.e-extra-small .e-input-group input.e-input:focus,
.e-extra-small .e-float-input input:focus,
.e-extra-small .e-float-input.e-control-wrapper input:focus {
    padding-left: 5px;
    text-indent: 0;
}
.e-input-group.e-extra-small input,
.e-input-group.e-extra-small input.e-input {
    box-sizing: border-box;
    height: 20px;
}
.e-input-group.e-extra-small .e-input-group-icon,
.e-input-group.e-control-wrapper.e-extra-small .e-input-group-icon {
    font-size: 10px;
    min-height: 14px;
    min-width: 14px;
    padding: 0;
}
.e-input-group.e-extra-small .e-clear-icon,
.e-input-group.e-control-wrapper.e-extra-small .e-clear-icon {
    min-width: 15px;
}

/* Form Input Highlighting/Active */
.e-input.e-active:not(.e-success):not(.e-warning):not(.e-error):not([disabled]),
.e-input.e-active:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]),
input.e-input.e-active:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
ejs-checkbox.e-active .e-icons {
	border-color: $input-active-border-color;
	-webkit-box-shadow: $input-border-shadow;
	box-shadow: $input-border-shadow;
}
.e-input.e-active:not(.e-success):not(.e-error).e-warning {
	-webkit-box-shadow: $input-warning-border-shadow;
	box-shadow: $input-warning-border-shadow;
}
.e-input.e-active:not(.e-error):not(.e-warning).e-success {
	-webkit-box-shadow: $input-success-border-shadow;
	box-shadow: $input-success-border-shadow;
}
.e-input.e-active:not(.e-success):not(.e-warning).e-error {
	-webkit-box-shadow: $input-error-border-shadow;
	box-shadow: $input-error-border-shadow;
}

div.label.e-active {
    text-shadow: 0 0 8px rgba(29, 64, 133, .9);
}

.e-active > .e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.e-active > .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
	border-color: $input-active-border-color;
	-webkit-box-shadow: $input-border-shadow;
	box-shadow: $input-border-shadow;
}
.e-active > .e-input-group:not(.e-disabled):not(.e-success):not(.e-error).e-warning,
.e-active > .e-input-group:not(.e-disabled):not(.e-success):not(.e-error).e-warning {
	-webkit-box-shadow: $input-warning-border-shadow;
	box-shadow: $input-warning-border-shadow;
}
.e-active > .e-input-group:not(.e-disabled):not(.e-error):not(.e-warning).e-success,
.e-active > .e-control-wrapper.e-input-group:not(.e-disabled):not(.e-error):not(.e-warning).e-success {
	-webkit-box-shadow: $input-success-border-shadow;
	box-shadow: $input-success-border-shadow;
}
.e-active > .e-input-group:not(.e-disabled):not(.e-success):not(.e-warning).e-error,
.e-active > .e-control-wrapper.e-input-group:not(.e-disabled):not(.e-success):not(.e-warning).e-error {
	-webkit-box-shadow: $input-error-border-shadow;
	box-shadow: $input-error-border-shadow;
}

.with-e-warning {
    .e-input-group, .mat-select {
	  	background-color: $brand-warning-light;
        border-color: $input-warning-color!important;

        &.e-input-focus, &.mat-select:focus {
            -webkit-box-shadow: $input-warning-border-shadow!important;
            box-shadow: $input-warning-border-shadow!important;
        }

	  	.e-input-group-icon {
		  	background-color: $brand-warning-light;
		}

		&.e-disabled,
		&.e-disabled .e-input.e-disabled,
		&.e-disabled .e-input-group-icon,
		&.disabled,
		&.disabled .mat-select-value,
		&.disabled .e-input-group,
		&.disabled .e-multi-select-wrapper .e-delim-values {
		  	background: $brand-warning-light !important;
		}
    }
}

.with-e-error {
  .e-input-group, .mat-select {
	border-color: $input-error-color!important;

	&.e-input-focus, &.mat-select:focus {
	  -webkit-box-shadow: $input-error-border-shadow!important;
	  box-shadow: $input-error-border-shadow!important;
	}
  }
  > div:not(.e-input-group) > .e-input {
	border-color: $input-error-color!important;

	&:focus {
	  -webkit-box-shadow: $input-error-border-shadow!important;
	  box-shadow: $input-error-border-shadow!important;
	}
  }
}

pms-radio-button-group {
  ejs-radiobutton.e-disabled.e-active {
	filter: alpha(Opacity=100);
	opacity: 1;
  }
}

/* Common Component Layouts */
.form-label-col {
    @extend .col-sm-4;
}
.form-input-col {
    @extend .col-sm-8;
}
.form-layout-col-sm-4-8-md-3-9 {
    .form-label-col {
        @extend .col-sm-4;
        @extend .col-md-3;
    }
    .form-input-col {
        @extend .col-sm-8;
        @extend .col-md-9;
    }
}

/* Number Stepper */
pms-number-stepper, ejs-numerictextbox {
    .e-input-group {
        height: $input-normal-height;
    }
    .e-input-group-icon {
        min-height: 50%!important;
        height: 50%;
        position: absolute;

        &.fa-caret-up {
            top: 0;
            right: 0;
            border-right: 0;
            border-top-right-radius: 3px;
        }
        &.fa-caret-down {
            bottom: 0;
            right: 0;
            border-left-width: 1px!important;
            border-top-right-radius: 0!important;
        }

        &.e-spin-up {
            top: 0;
            right: 0;
            border-left-width: 1px!important;
            border-top-right-radius: 3px!important;
            border-bottom-right-radius: 0!important;
        }
        &.e-spin-down {
            bottom: 0;
            right: 0;
            border-right: 0!important;
            border-bottom-right-radius: 3px!important;
        }
    }
}
pms-number-stepper .e-input-group-icon {
    font-size:18px!important;
}

/* Inplace Editor */
.inplace-editor {
    display: inline-block;
    width: auto;

    .inplace-editor-component {
        margin-right: 0.5em;
    }
    .inplace-editor-component, .inplace-editor-actions {
        display: inline-block;
        vertical-align: middle;
    }
}

/* Schedule */
.e-schedule .e-appointment.e-appointment-active {
  border: 2px solid $grey-light!important;
  box-shadow: $schedule-event-box-shadow-1, $schedule-event-box-shadow-2, $schedule-event-box-shadow-3;
  z-index: 1;
}

/* Pulled directly from primeflex styles */
.schedule-grid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}
.schedule-fixed-column {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	padding: 0 5px;
}
.schedule-flexible-column {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	padding: 0 5px;
}

.e-schedule {
  	.e-resource-cells {
	  	padding: 8px!important;
	  	vertical-align: top!important;
	}

  	.e-vertical-view {
		.e-header-cells {
			.e-header-date {
				font-size: 16px;
				display: inline-block;
				border-radius: 100px;
				text-align: left;
				width: 25px;
				height: 25px;
				line-height: 25px;
			}
		  	&.e-current-day .e-header-date {
			  background-color: $brand-primary;
			  color: #fff;
			  text-align: center;
			}
		}
	}

	.e-appointment {
		border-color: $grey-dd!important;

		.e-appointment-details {
			padding: 2px 4px!important;

			.fc-event-info {
				overflow: hidden;
				font-size: 12px;
				line-height: 1.25;

				p {
					margin: 0;
					font-size: 11px;
				}

				.fc-event-time {
					font-weight: bold;
				}
			}

			.event-icon{
				line-height: 1;
				position: absolute;
				top: 2px;
				right: 2px;
			}
		}
	}

	.e-timeline-day-view .e-header-cells, .e-day-view .e-header-cells {
		display: none;
	}

	.e-timeline-view .e-appointment, .e-schedule .e-timeline-month-view .e-appointment {
		height: 50px;
	}

	.rev-schedule-cell-alt {
		&.e-work-hours,
		&.e-header-cells,
		&.e-resource-cells {
		  	background-color: lighten($state-info-bg, 5%) !important;
		}
	  	&.e-work-hours:hover {
		  	background-color: $state-info-bg !important;
		}
	}
}

.additionalViews.e-schedule {
	.e-header-row {
		.e-resource-cells:nth-child(2n) {
		  background-color: lighten($state-info-bg, 5%) !important;
		}
	}
}

/* Vertical Inline Calendar */
.vertical-datepicker {
    .ui-datepicker {
        padding: 0.5em;
    }

    .ui-datepicker table {
        font-size: 12px;

        td {
            padding: 0.25em;
        }
    }

    .ui-datepicker-next, .ui-datepicker-prev {
        display: none;
    }

    .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group {
        border-right: none;
        padding-right: 0px;
        padding-left: 0px;
        display: block;
    }
}

/* 3-month calendar pane in the schedule */
.schedule-calendar-pane {
	width: 275px !important;

	.e-calendar {
		td.e-focused-date span.e-day {
			background: inherit;
		}

		td.e-other-month span.e-day {
			visibility: hidden;
		}

		.e-icon-container {
			visibility: hidden;
		}
	}
}

/* Calendar */
.e-calendar {
	max-width: 254px;
	min-width: 248px;
	padding: 8px;

	.e-header {
		.e-date-icon-prev::before {
			content: '\e990';
		}
		.e-date-icon-next::before {
			content: '\e207';
		}
	}
}

/* Appointment List */
.appointment-list-link {
    .appointment-item {
        cursor: pointer;

        &:hover > .appointment-info, &.active > .appointment-info {
            background-color: $grey-lighter;
        }
    }
}

/* Time Slot Slider */
.e-slider-schedule-time-slot {
    .e-control-wrapper.e-slider-container {
        &.e-horizontal {
            height: 40px;
            margin-top: -10px;

            .e-slider {
                top: 0;
            }
        }

        .e-scale.e-h-scale .e-tick .e-tick-value.e-tick-after, .e-scale.e-h-scale .e-tick .e-tick-value.e-tick-both {
            bottom: -15px;
        }
    }
}

/* Hide Icon On Sync Fusion DatePicker */
.ejs-hide-datepicker-icon {
    .e-input-group .e-input-group-icon.e-date-icon.e-icons {
        display: none;
    }
}

/* Remove extra border inside datepicker when in an input group */
.e-input-group .ejs-hide-datepicker-icon .e-input-group.e-control-wrapper {
    border: none;
    border-radius: 0;
}

/* Tables */
.table {
  .bg-default {
	background-color: $state-default-bg!important;
  }
  .bg-info {
	background-color: $state-info-bg!important;
  }
}
.e-grid {
    .e-table {
        margin: 0;
        min-width: 100% !important;

        .e-filterbar {
            display: none;
        }

        .e-row.bg-primary:hover .e-rowcell {
            background-color: darken($brand-primary, 10%)!important;
        }
        .e-row.bg-success:hover .e-rowcell {
            background-color: darken($state-success-bg, 10%)!important;
        }
        .e-row.bg-info:hover .e-rowcell {
            background-color: darken($state-info-bg, 10%)!important;
        }
        .e-row.bg-warning:hover .e-rowcell {
            background-color: darken($state-warning-bg, 10%)!important;
        }
        .e-row.bg-danger:hover .e-rowcell {
            background-color: darken($state-danger-bg, 10%)!important;
        }
    }
    &.e-filterbar-visible .e-table {
        .e-filterbar {
            display: table-row;
        }
    }

  	.e-gridheader .e-headercell .e-rhandler:after {
		content: "";
		position: absolute;
		z-index: 1;
		display: block;
		left: calc(50% - 1px);
		width: 2px;
		height: 30%;
		top: calc(50% - 15%);
		background-color: rgba(221, 221, 221, 0.5);
	}
  	&.e-resize-lines .e-headercell {
		.e-rhandler,
		.e-rsuppress {
		  	border-right: 0!important;
		}
	}

    .e-headercell, .e-detailheadercell {
        vertical-align: middle!important;
    }

    &.e-gridheader-hidden {
        .e-gridheader {
            display: none;
        }
        .e-gridcontent {
            border-top: 1px solid $grey-dd;
        }
    }

    &.table-rowlink {
        .e-gridcontent {
            .e-table {
                td:not(.rowlink-skip) {
                    cursor: pointer;
                }
            }
        }
    }
    .e-gridcontent {
        .e-table .rowlink {
            td:not(.rowlink-skip) {
                cursor: pointer;
            }
        }
	  	.e-table .e-rowcell {
			.e-btn {
				font-size: $font-size;
				line-height: 1;
				padding: 5px;
				margin-top: -1px;
				margin-bottom: -1px;

				.e-btn-icon {
				  font-size: $font-size;
				}
			}

			.e-multiselect {
			  	.e-multi-select-wrapper {
					min-height: 20px !important;
					height: 20px !important;

					.e-ddl-icon {
						height: 20px !important;
						min-height: 20px !important;
						width: 20px;
						min-width: 20px;
						font-size: 10px;
					}

					.e-delim-values {
						line-height: 20px;
						font-size: 12px;
						padding-left: 5px;
					}
					.e-delim-values .e-remain {
						font-size: 12px;
						padding-left: 5px;
					}

					.e-chips-close {
						height: 20px;
						width: 10px;
						right: 20px;
					}
					.e-chips-close.e-close-hooker::before {
					  	font-size: 9px;
					}

					.e-searcher {
					  	max-width: 30px!important;
					}

					.e-list-item {
					  	min-width: 50px;
					}
			  	}
			}

			.e-input, .e-input-group, .e-input-group.e-control-wrapper {
			  	margin-bottom: 0;
			  	margin-top: 0;
			}

			input.e-input, textarea.e-input,
			.e-input-group,
			.e-input-group.e-control-wrapper,
			.e-input-group.e-disabled,
			.e-input-group.e-control-wrapper.e-disabled {
			  	font-size: 12px;
			}

			input.e-input,
			.e-input-group input.e-input,
			.e-input-group.e-control-wrapper input.e-input,
			.e-float-input input, .e-float-input.e-control-wrapper input,
			.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input,
			.e-input-group input.e-input:focus, .e-input-group.e-control-wrapper input.e-input:focus,
			.e-float-input input:focus, .e-float-input.e-control-wrapper input:focus,
			.e-input-group.e-control-wrapper input.e-input:focus,
			.e-input-group input.e-input:focus, .e-float-input input:focus,
			.e-float-input.e-control-wrapper input:focus {
				padding-left: 5px;
				text-indent: 0;
				text-align: left!important;
			}

			.e-input-group input, .e-input-group input.e-input {
				box-sizing: border-box;
				height: 20px;
				min-width: 18px;
			}
			.e-input-group .e-input-group-icon, .e-input-group.e-control-wrapper .e-input-group-icon {
				font-size: 10px;
				min-height: 14px;
				min-width: 14px;
				padding: 0px;
			}

			.e-input-group .e-clear-icon, .e-input-group.e-control-wrapper .e-clear-icon {
			  	min-width: 10px;
			}

			.e-float-input .e-clear-icon::before,
			.e-float-input.e-control-wrapper .e-clear-icon::before,
			.e-input-group .e-clear-icon::before,
			.e-input-group.e-control-wrapper .e-clear-icon::before {
			  	font-size: 9px;
			}

			textarea.e-input {
				padding: 3px 5px !important;
				resize: none;
			}

			pms-number-stepper, ejs-numerictextbox {
				.e-input-group {
				  	height: 23px;
				}
				.e-input-group-icon {
					min-width: 18px;
					width: 18px;
					font-size: 12px!important;
				}
			}
		}
    }

    .e-altrow {
        background-color: $grey-f9;
    }

	.e-rowcell, .e-headercell {
	  	.e-checkbox-wrapper, .e-css.e-checkbox-wrapper {
			.e-frame {
				width: 15px;
				height: 15px;
				line-height: 15px;
			}
			.e-check {
				font-size: 12px;
			}
		}
	}

  	.e-emptyrow td {
	  	text-align: center;
	}

	&.e-gridhover tr[role=row]:not(.e-editedrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
	&.e-gridhover tr[role=row]:hover .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
	&.e-gridhover tr[role=row]:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
	&.e-rtl .e-gridhover tr[role=row]:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
	&.e-gridhover tr[role=row]:hover .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
	  background-color: lighten($state-info-bg, 5%);
	}

  	td.e-active {
	  background-color: $state-info-bg;
	}
}

.e-pager {
  padding: $pager-padding !important;

  .e-pagercontainer {
	vertical-align: middle;
	margin: 0;
  }
  .e-pagesizes {
	vertical-align: middle;
  }
  .e-pagerdropdown {
	height: $input-normal-height;
	margin-top: 0;
	vertical-align: middle;
  }
  .e-pagerconstant {
	margin: 0 0 0 6px;
	vertical-align: middle;
	line-height: $input-normal-height;
  }
  div.e-parentmsgbar {
	padding: 0;
	line-height: $input-normal-height;
  }

  @media (max-width: 769px) {
	.e-mfirst,
	.e-mprev,
	.e-mnext,
	.e-mlast {
	  line-height: $input-normal-height;
	  padding: 0 5%;
	}
  }
}
.e-toolbar, .e-toolbar .e-toolbar-items {
  background: $grey-white;
}

/* AG Grid */
@include ag.grid-styles((
	theme: alpine,
	// COLOURS
	alpine-active-color: $brand-secondary,
	header-background-color: #fff,
	odd-row-background-color: $grey-f9,
	border-color: $grey-dd,

	// SIZING
	grid-size: 5px,
	row-height: calc(var(--ag-grid-size) * 6.75),
	header-height: calc(var(--ag-grid-size) * 6.75),
	cell-horizontal-padding: calc(var(--ag-grid-size) * 1.5),

	// FONTS
	font-family: $font-family-sans-serif,
	font-size: $font-size-base,
));
.ag-theme-alpine {

  border: 1px solid $grey-dd;

  &.ag-popup {
	border: none;
  }

  .ag-root-wrapper {
	border: none;
  }

  .ag-row {
	font-size: $font-size-base;
	color: $text-color;

	&.row-link {
	  cursor: pointer;
	}

	&.ag-row-highlight-below:not(.ag-row-selected) {
	  background-color: var(--ag-row-hover-color);
	}
  }

  .ag-row-hover {
	background-color: lighten($state-info-bg, 5%);
  }
  .ag-row-hover.bg-primary {
	background-color: darken($brand-primary, 5%)!important;
	&::before {
	  background: none;
	}
  }
  .ag-row-hover.bg-success {
	background-color: darken($state-success-bg, 5%)!important;
	&::before {
	  background: none;
	}
  }
  .ag-row-hover.bg-info {
	background-color: darken($state-info-bg, 5%)!important;
	&::before {
	  background: none;
	}
  }
  .ag-row-hover.bg-warning {
	background-color: darken($state-warning-bg, 5%)!important;
	&::before {
	  background: none;
	}
  }
  .ag-row-hover.bg-danger {
	background-color: darken($state-danger-bg, 5%)!important;
	&::before {
	  background: none;
	}
  }

  .ag-row-selected {
	background-color: $state-info-bg;
  }

  .ag-cell {
	line-height: 1.4em;
	padding: var(--ag-cell-horizontal-padding);

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
	}

	/* Firefox */
	input[type=number] {
	  -moz-appearance: textfield;
	}

	&.display-flex rev-template-cell-renderer {
	  width: 100%;
	}
  }

  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
	height: 1.4em;
  }

  .ag-ltr .ag-selection-checkbox {
	margin-top: -1.5px;
	margin-left: -1px;
  }

  .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-ltr .ag-cell-range-single-cell,
  .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
  .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-rtl .ag-cell-range-single-cell,
  .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
	border-color: transparent;
  }

  .ag-layout-auto-height, .ag-layout-print {
	.ag-center-cols-clipper, .ag-center-cols-container {
	  min-height: var(--ag-row-height);
	}
  }
  .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
	padding-top: var(--ag-row-height);
  }

  &.ag-no-header .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
	padding-top: 0;
  }

  .ag-floating-bottom-container {
	  .ag-row {
		background-color: $grey-lighter;
		font-weight: 700;
	  }
  }

  .ag-body-horizontal-scroll-viewport {
	overflow-x: auto!important;
  }
}

rev-ag-grid-container {
  .e-toolbar {
	border: 1px solid $grey-dd;
	border-radius: 0;
	border-bottom: 0!important;
  }

  .e-pager {
	border-top: none;
  }
}

.e-grid, rev-ag-grid-container {
  .e-toolbar {
	.e-toolbar-items {
	  .e-toolbar-item:not(.e-separator) {
		padding: 2.5px;
	  }

	  .e-toolbar-item {
		label {
		  margin: 0 0.5em 0 0;
		}
	  }

	  .e-toolbar-left {
		.e-toolbar-item {
		  margin-right: 1em;
		  line-height: 34px;
		}
	  }
	}
  }
}

.e-grid .e-gridcontent .e-table .e-rowcell, .ag-theme-alpine .ag-body-viewport .ag-row .ag-cell, .table tr td {
  .e-btn {
	font-size: $font-size;
	line-height: 1;
	padding: 5px;
	margin-top: -1px;
	margin-bottom: -1px;

	.e-btn-icon {
	  font-size: $font-size;
	}
  }

  .e-multiselect {
	.e-multi-select-wrapper {
	  min-height: 20px !important;
	  height: 20px !important;

	  .e-ddl-icon {
		height: 20px !important;
		min-height: 20px !important;
		width: 20px;
		min-width: 20px;
		font-size: 10px;
	  }

	  .e-delim-values {
		line-height: 20px;
		font-size: 12px;
		padding-left: 5px;
	  }
	  .e-delim-values .e-remain {
		font-size: 12px;
		padding-left: 5px;
	  }

	  .e-chips-close {
		height: 20px;
		width: 10px;
		right: 20px;
	  }
	  .e-chips-close.e-close-hooker::before {
		font-size: 9px;
	  }

	  .e-searcher {
		max-width: 30px!important;
	  }

	  .e-list-item {
		min-width: 50px;
	  }
	}
  }

  .e-input, .e-input-group, .e-input-group.e-control-wrapper {
	margin-bottom: 0;
	margin-top: 0;
  }

  input.e-input, textarea.e-input,
  .e-input-group,
  .e-input-group.e-control-wrapper,
  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled {
	font-size: 12px;
  }

  input.e-input,
  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-float-input input, .e-float-input.e-control-wrapper input,
  .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input,
  .e-input-group input.e-input:focus, .e-input-group.e-control-wrapper input.e-input:focus,
  .e-float-input input:focus, .e-float-input.e-control-wrapper input:focus,
  .e-input-group.e-control-wrapper input.e-input:focus,
  .e-input-group input.e-input:focus, .e-float-input input:focus,
  .e-float-input.e-control-wrapper input:focus {
	padding-left: 5px;
	text-indent: 0;
	text-align: left!important;
  }

  input.e-input, .e-input-group input, .e-input-group input.e-input {
	box-sizing: border-box;
	height: 21px;
	min-width: 18px;
  }
  .e-input-group input, .e-input-group input.e-input {
	height: 19px;
  }
  .e-input-group .e-input-group-icon, .e-input-group.e-control-wrapper .e-input-group-icon {
	font-size: 10px;
	min-height: 14px;
	min-width: 14px;
	padding: 0px;
  }

  .e-input-group .e-clear-icon, .e-input-group.e-control-wrapper .e-clear-icon {
	min-width: 10px;
  }

  .e-float-input .e-clear-icon::before,
  .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-input-group .e-clear-icon::before,
  .e-input-group.e-control-wrapper .e-clear-icon::before {
	font-size: 9px;
  }

  textarea.e-input {
	padding: 3px 5px !important;
	resize: none;
  }

  pms-number-stepper, ejs-numerictextbox {
	.e-input-group {
	  height: 21px;
	}
	.e-input-group-icon {
	  min-width: 18px;
	  width: 18px;
	  font-size: 12px!important;
	}
  }
  .e-checkbox-wrapper {
	display: inline-block;
	vertical-align: middle;
  }
}
.e-grid .e-gridcontent .e-table .e-rowcell, .ag-theme-alpine .ag-body-viewport .ag-row .ag-cell {
  .e-btn,
  input.e-input,
  .e-input-group,
  .e-checkbox-wrapper {
	margin-top: -2.5px!important;
  }
  .e-input-group input, .e-input-group input.e-input {
	margin-top: 0!important;
  }
}

.editable-cell {
  position: relative;
  margin-right: -6.5px;

  .editable-cell-value {
	display: inline-block;
  }
  .editable-cell-icon {
	font-size: 10px;
	display: inline-block;
	width: 12px;
  }
}

/* Previously PrimeNg Styles (still used by panels) */
.ui-widget, .ui-widget * {
	box-sizing: border-box;
}

.ui-helper-hidden {
	display: none !important;
}

.ui-panelmenu {
	width: auto;
}

.ui-panelmenu .ui-menu-separator {
	border-width: 1px 0 0 0;
}

.ui-panelmenu .ui-panelmenu-content-wrapper {
	overflow: hidden;
}

.ui-panelmenu .ui-panelmenu-header {
	margin: -1px 0 0 0;
	zoom: 1;
}

.ui-panelmenu .ui-panelmenu-header-link {
	padding: .5em;
	display: block;
	text-decoration: none;
}

.ui-panelmenu .ui-panelmenu-icon {
	vertical-align: middle;
}

.ui-panelmenu .ui-menuitem-text {
	vertical-align: middle;
}

.ui-panelmenu .ui-menuitem-icon {
	margin-right: .25em;
	vertical-align: middle;
}

.ui-panelmenu .ui-panelmenu-content {
	padding: 0.25em;
	border-top: 0;
	margin-bottom: 1px;
}

.ui-panelmenu .ui-submenu-list {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-left: 1.5em;
}

.ui-panelmenu .ui-panelmenu-content > .ui-panelmenu-root-submenu >.ui-submenu-list {
	margin-left: 0;
}

.ui-panelmenu .ui-menuitem {
	overflow: hidden;
	margin: .125em 0;
}

.ui-panelmenu .ui-menuitem-link {
	padding: .25em;
	display: block;
	text-decoration: none;
}

/* PanelMenu */
body {
    .ui-panelmenu {
		font-size: $font-size;
        border: 1px solid $panel-default-border;
	  	border-radius: $panel-border-radius;

        .ui-icon {
            position: static;
        }

        .ui-menuitem-text, .ui-panelmenu-icon {
            vertical-align: middle;
        }

        .ui-panelmenu-icon {
            flex: 0 0 auto;
			width: 20px;
			text-align: center;
		  	align-self: center;
        }

        .ui-panelmenu-header {
            padding: 0;
            margin: 0;

            > a {
                border: none;
                background-color: $grey-f9;
                color: $grey-dark;
                padding: 0.5em 0.5em 0.5em 0.25em;
                font-weight: normal;
                position: static;
                font-size: $font-size;
                display: flex;

                .ui-panelmenu-icon {
                    color: $grey-88;
                }
            }

            &:not(.ui-state-active) {
                > a:hover {
                    outline: 0 none;
                    border: none;
                    background-color: $state-default-bg;
                    color: $grey-dark;

                    .ui-panelmenu-icon {
                        color: $grey-dark;
                    }
                }
            }

            &.ui-state-active {
                > a {
                    border: none;
                    background-color: $grey-cc;
                    color: $grey-dark;

                    .ui-panelmenu-icon {
                        color: $grey-dark;
                    }

                    &:hover {
                        outline: 0 none;
                        border: none;
                        background-color: darken($grey-cc, 10%);
                        color: $grey-dark;

                        .ui-panelmenu-icon {
                            color: $grey-dark;
                        }
                    }
                }
            }
        }

        .ui-panelmenu-panel {
            margin-top: 0;
            border-top: 1px solid $panel-default-border;

            &:first-child {
                margin-top: 0;
                border-top: none;
			  	border-top-left-radius: $panel-border-radius;
			  	border-top-right-radius: $panel-border-radius;

			  	.ui-panelmenu-header > a {
					border-top-left-radius: $panel-border-radius;
					border-top-right-radius: $panel-border-radius;
				}
            }
			&:last-child {
			  border-bottom-left-radius: $panel-border-radius;
			  border-bottom-right-radius: $panel-border-radius;

			  .ui-panelmenu-header > a {
				border-bottom-left-radius: $panel-border-radius;
				border-bottom-right-radius: $panel-border-radius;
			  }
			}
        }

        .ui-panelmenu-content {
            padding: 0;
            background-color: $grey-white;
            border: none;
            margin-top: 0;
            margin-bottom: 0;
            position: static;
            border-top: 0 none;

            .ui-menuitem {
                margin: 0;
                border-radius: 0;

                .ui-menuitem-link {
                    padding: 5px 10px;
                    color: $grey-dark;
                    border-radius: 0;

                    &:hover {
                        background-color: $state-default-bg;

                        .ui-menuitem-icon, .ui-panelmenu-icon {
                            color: $grey-dark;
                        }

                        .ui-menuitem-text {
                            color: $grey-dark;
                        }
                    }
                }
            }
        }

        .ui-menuitem-link {
            display: flex;
            text-decoration: none;

            &:not(.ui-state-active) {
                .ui-panelmenu-icon {
                    color: $grey-88;
                }

                &:hover {
                    .ui-panelmenu-icon {
                        color: $grey-dark;
                    }
                }
            }

            &.ui-state-active {
                background-color: $state-default-bg;

                .ui-panelmenu-icon {
                    color: $grey-dark;
                }
            }
        }

        .ui-submenu-list {
            margin:0;

            .ui-submenu-list .ui-menuitem-link {
                padding-left: 3em;
            }
        }
    }
}

/* TreeView */
.e-treeview {
    .e-ul:first-child {
        padding: 0 0 0 5px;
    }
}

/* uploader */
.hide-upload-actions > .e-upload >.e-upload-actions{
	display: none;
}

/* http error dialog */
.http-error-dialog {
    .e-dlg-content {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
    }
}

/* Allows custom uib-popovers to be larger than the standard size */
.popover-max-width {
	max-width: 100%;
}

/* Spinner */
.e-spinner-container {
	position: relative;
	min-height: 50px;
}
.e-spinner-target {
	position: absolute;
	height: 100%;
	width: 100%;
}

/* The content area where tests are displayed for modifying their layout */
.screen-library-layout-content {
  overflow: auto;
  height: 100vh;
  position: relative
}

/* EMR Test layout */
.emr-test-wrapper {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    .panel {
        margin-bottom: 0 !important;
    }
    .panel-body {
        padding: 6px;
    }
}
.emr-test {
    display: inline-block;
}
.emr-test-heading {
    color: $panel-default-text;
    background-color: $state-default-bg;
    border-bottom: 1px solid $panel-default-border;
}

/* EMR Test inputs */
.emr-test-form {
  position: relative;
  .label {
	padding: 0;
	font-family: $font-family!important;
	font-size: 11px;
	font-weight: bold!important;
	color: $grey-dark;
	line-height: 1.25;
	text-align: left;
	white-space: normal;
	border-radius: 0;
  }

  .mat-select {
	height: 22px;
  }

  .mat-select-value {
	height: 20px;
  }

  .mat-select-placeholder {
	height: 20px;
	line-height: 20px;
	padding-left: 5px;
  }

  pms-dropdown .mat-select .e-input-group.e-extra-small {
	height:19px;
  }

  pms-dropdown .mat-select .e-input {
	text-overflow: clip;
  }

  .e-multiselect {
	overflow: hidden;
	.e-multi-select-wrapper {
	  min-height: 20px !important;
	  height: 20px !important;

	  .e-ddl-icon {
		height: 20px !important;
		min-height: 20px !important;
		width: 20px;
		min-width: 20px;
		font-size: 10px;
	  }

	  .e-delim-values {
		line-height: 20px;
		font-size: 12px;
		padding-left: 5px;
	  }
	  .e-delim-values .e-remain {
		font-size: 12px;
		padding-left: 5px;
	  }

	  .e-chips-close {
		height: 20px;
		width: 10px;
		right: 20px;
		display: none!important;
	  }
	  .e-chips-close.e-close-hooker::before {
		font-size: 9px;
	  }

	  .e-searcher {
		max-width: 30px!important;
	  }

	  .e-list-item {
		min-width: 50px;
	  }
	}
  }

  .e-input.e-extra-small, .e-input-group.extra-small, .e-input-group.e-control-wrapper.extra-small {
	margin-bottom: 0;
  }

  input.e-input.e-extra-small, textarea.e-input.e-extra-small,
  .e-extra-small input.e-input, .e-extra-small textarea.e-input,
  .e-input-group.e-extra-small, .e-extra-small .e-input-group,
  .e-input-group.e-control-wrapper.e-extra-small,
  .e-extra-small .e-input-group.e-control-wrapper,
  .e-input-group.e-extra-small.e-disabled, .e-extra-small .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-extra-small.e-disabled, .e-extra-small .e-input-group.e-control-wrapper.e-disabled {
	font-size: 12px;
	line-height: 1.1em;
  }

  input.e-input.e-extra-small, .e-extra-small input.e-input,
  .e-input-group.e-extra-small input.e-input,
  .e-input-group.e-control-wrapper.e-extra-small input.e-input,
  .e-float-input.e-extra-small input, .e-float-input.e-control-wrapper input.e-extra-small,
  .e-float-input.e-extra-small input, .e-float-input.e-control-wrapper input.e-extra-small,
  .e-input-group input.e-input.e-extra-small, .e-input-group.e-control-wrapper input.e-input.e-extra-small,
  .e-extra-small .e-float-input input, .e-extra-small .e-float-input.e-control-wrapper input,
  .e-extra-small .e-input-group input.e-input, .e-extra-small .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-extra-small input.e-input:focus, .e-input-group.e-control-wrapper.e-extra-small input.e-input:focus,
  .e-float-input.e-extra-small input:focus, .e-float-input.e-control-wrapper.e-extra-small input:focus,
  .e-extra-small .e-input-group.e-control-wrapper input.e-input:focus,
  .e-extra-small .e-input-group input.e-input:focus, .e-extra-small .e-float-input input:focus,
  .e-extra-small .e-float-input.e-control-wrapper input:focus {
	padding-left: 5px;
	text-indent: 0;
	text-align: left!important;
  }

  .e-input-group.e-extra-small input, .e-input-group.e-extra-small input.e-input {
	box-sizing: border-box;
	height: 20px;
	min-width: 18px;
  }
  .e-input-group.e-extra-small .e-input-group-icon, .e-input-group.e-control-wrapper.e-extra-small .e-input-group-icon {
	font-size: 10px;
	min-height: 12px;
	width: 20px;
	padding: 0px;
  }

  .e-input-group.e-extra-small .e-clear-icon, .e-input-group.e-control-wrapper.e-extra-small .e-clear-icon {
	min-width: 10px;
	display: none!important;
  }

  .e-input-group.e-extra-small:hover,
  .e-input-group.e-extra-small:focus,
  .e-input-group.e-control-wrapper.e-extra-small:hover,
  .e-input-group.e-control-wrapper.e-extra-small:focus
  {
	.e-clear-icon, .e-chips-close {
	  display: flex !important;
	}
  }

  .e-float-input .e-clear-icon::before,
  .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-input-group .e-clear-icon::before,
  .e-input-group.e-control-wrapper .e-clear-icon::before {
	font-size: 9px;
  }

  .e-checkbox-wrapper, .e-css.e-checkbox-wrapper {
	.e-frame {
	  width: 15px;
	  height: 15px;
	  line-height: 15px;
	}
	.e-check {
	  font-size: 12px;
	}
  }

  textarea.e-input {
	padding: 3px 5px !important;
	resize: none;
  }

  pms-number-stepper, ejs-numerictextbox {
	.e-input-group {
	  height: 22px;

	  .e-input {
		height: 20px!important;
	  }
	}
	.e-input-group-icon {
	  min-width: 18px;
	  width: 18px;
	  font-size: 12px!important;
	}
  }
}

.e-popup.e-extra-small {
  min-width: 120px !important;

  .e-selectall-parent, .e-list-item {
	padding-left: 5px;
	padding-right: 5px;
	text-indent: 0px !important;
  }

  .e-selectall-parent {
	padding-left: 6px;
	padding-right: 6px;
  }
}

td .personImage {
  max-width: 35px;
  max-height: 35px;
}

.eyedock-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: contain;
    background-image: url("../assets/eyedock.png");
    vertical-align: middle;
}

.form-control, .input-group-addon {
  border:1px solid $grey-cc;
}

.font-italic {
  font-style: italic;
}

.font-weight-bold {
  font-weight: bold;
}

.e-clear-icon-show {
  display: flex !important;
}

.e-input-group.e-disabled .e-clear-icon {
  display: none!important;
}

/* Sidebar Sub Nav */
.e-sidebar-sub-nav {
  background: none;
  box-shadow: none!important;
  position: static;
  height: auto;

  &.e-left {
	border-right: none;
	padding-right: 10px;
  }

  .sub-nav {
	margin-bottom: 0;

	.sub-nav-toggle {
	  .sub-nav-toggle-open {
		display: none;
	  }
	}
  }

  &.e-close {
	.sub-nav {
	  .nav-name {
		display: none;
	  }

	  .sub-nav-toggle {
		.sub-nav-toggle-close {
		  display: none;
		}
		.sub-nav-toggle-open {
		  display: inline-block;
		}
	  }
	}
  }
}

.ag-tooltip {
  position: absolute !important;
}

.ag-popup-child {
  z-index: 3000;
}

/* Patient Full Screen */
body.patient-module-full-screen {
  #header,
  #patient-panel > .panel-heading,
  #patient-panel #patient-info-panel #patient-info-actions,
  #patient-panel .rev-app-header-toolbar {
	display: none!important;
  }

  #wrapper {
	margin: 0;

	> .container-fluid {
	  margin: 0;
	  padding: 0;
	}
  }

  #patient-panel {
	#patient-info-panel {
	  background-color: $grey-f9;
	  box-shadow: 0 2px 1px rgba(0,0,0,0.05);
	  padding: 5px 10px;
	  position: fixed;
	  top: 0;
	  right: 0;
	  left: 0;
	  z-index: 1030;

	  .media {
		.media-left .personImage {
		  max-width: 34px;
		  max-height: 34px;
		}
		.media-body {

		  .media-heading, .list-inline {
			margin: 0;
		  }

		  .media-heading {
			font-size: 14px;
			font-weight: bold;
			margin-right: 10px;
		  }

		  .list-inline {
			display: block!important;
			font-size: 12px;
			line-height: 1.1;
			margin-left: -5px;
		  }
		}

		.media-right {
		  padding-left: 50px
		}
	  }

	  #patient-info-actions-ellipsis {
		position: absolute;
		top: 5px;
		right: 43px;
	  }
	}

	#patient-panel-body {
	  padding: 10px;
	}
  }

  #footer {
	position: static!important;
  }
}

#patient-panel #patient-info-panel .media .media-right {
  white-space: nowrap;
}

/* Invoices */
pms-invoice-status {
  small a {
	font-size: $font-size;
  }
}

/* Open Edge */
.oe-card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  background-image: url("../assets/creditCard/credit-card-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  width: 484px;
  margin: 0 auto;

  .oe-card-number {
	grid-column-start: 1;
	grid-column-end: 4;
	padding-top: 40px;
	margin-left: 20px;
	margin-right: 20px;
  }
  .oe-card-data {
	display: grid;
	max-height: 10px;
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr 1fr max-content;
	align-items: end;
	margin-left: 20px;
	margin-right: 20px;
  }
  .oe-card-expiration {
	width: 110px;
	margin-left: 220px;
  }
  .oe-card-expiration-data {
	width: 110px;
	margin-left: 220px;
	margin-top: -20px;
  }
  .oe-card-cvv {
	display: block;
	padding-left: 20px;
	width: 114px;
  }
  .oe-card-label {
	color: white;
	padding-top: 20px;
	letter-spacing: 2px;
	font-size: $font-size;
	font-family: $font-family;
	text-transform: uppercase;
  }
  iframe {
	width: 100%;
  }
}


/* Print Styles */
@media print {
  body::-webkit-scrollbar {
	display:none;
  }
  body {
	font-size: $font-size-smaller;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	color-adjust: exact;
	-webkit-print-color-adjust: exact;
  }

  pms-patient-navigation-bar,
  .e-toast,
  pms-footer,
  .e-tooltip-wrap,
  .tippy-box
  {
	display: none!important;
	visibility: hidden!important;
  }

  /* Printing for ngx-extended-pdf-viewer */
  #printContainer > div {
	display: inline;
	overflow-y: visible;
  }

  .ag-theme-alpine {
	font-size: $font-size-smaller;
  }
}

.overlay-container {
  position: absolute;
  z-index: 1019;
  display: block;
  opacity: .3;
  background-color: #383838;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.loading-overlay-spinner {
  z-index: 1020;
  display: inline !important;
  position: absolute;
  width: 100%;
  top: 50%;
}

ejs-datepicker.e-disabled {
	all: unset;
}

.e-toast-top-right {
	margin-top: 35px;
}

.pintura-editor {
	--color-background: 0, 0, 0;
	--color-foreground: 255, 255, 255;
}

/* Legacy App Style Overrides */
rev-patients-summary,
rev-patients-optical-summary,
rev-patients-immunizations,
rev-patients-referrals {
  .panel {
	border-color: $panel-default-border;
	.panel-heading {
	  background-color: $panel-default-heading-bg;
	  color: $panel-default-text;
	  border-color: $panel-default-border;
	}
  }
  .table-bordered {
	> thead > tr > th,
	> tbody > tr > td {
	  border-left: none!important;
	  border-right: none!important;
	}
  }
}
.rev-inventory-app,
.rev-tasks-app,
.rev-messaging-app,
.rev-connect-app {
  .app-panel,
  #messages-panel {
	background: none;
	border: none;
	border-radius: 0;
	box-shadow: none;

	> .panel-heading {
	  background-color: $grey-f9;
	  box-shadow: 0 2px 1px rgba(0,0,0,0.05);
	  margin: -10px -10px 10px;
	  padding: 9px 45px 12.5px 10px;

	  > .panel-title {
		font-size: ceil(($font-size-base + 1));
	  }

	  .nav-tools {
		top: 4.5px;

		> li {
		  margin: 0 0 0 0.5em;

		  > a {
			padding: 7.5px 9px;
		  }

		  &:first-child > a {
			background-color: $btn-success-bgcolor;
			color: $btn-success-color;

			&:hover, &:focus, &:active {
			  background-color: $btn-success-hover-bgcolor;
			}
		  }
		}
	  }
	}
	&.with-nav-tabs > .panel-heading {
	  padding-top: 0;
	  padding-bottom: 1px;
	  > .nav-tabs > li > a {
		font-size: $tab-nrml-font-size;
		line-height: 15px;
		padding: 10px;
	  }
	}
	> .tab-content,
	> .panel-body {
	  background-color: $panel-bg;
	  border: 1px solid $panel-default-border;
	  border-radius: $panel-border-radius;
	  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	}
  }
  .table-bordered {
	> thead > tr > th,
	> tbody > tr > td {
	  border-left: none!important;
	  border-right: none!important;
	}
  }
}
.rev-inventory-app {
  rev-inventory-product-search,
  rev-inventory-vsp-search {
	form {
	  margin-bottom: 0!important;
	}
  }
  rev-inventory-vsp-search .form-inline .row {
	margin-left: 0;
	margin-right: 0;
  }
  rev-inventory-products-dashboard {
	.alert.alert-warning {
	  margin: -10px -10px 10px;
	  border-radius: 0;
	}
  }
  rev-inventory-reconciliation-report-batches-dashboard {
	.app-panel .tab-content {
	  background: none!important;
	  border: none!important;
	  border-radius: 0!important;
	  box-shadow: none!important;
	}
  }
}
.rev-connect-app {
  rev-connect-bucket-list {
	.panel-default.panel-sm {
	  .panel-heading {
		background-color: $state-default-bg;
	  }
	}
  }
  connect-settings {
	.panel .panel-heading {
	  padding: 10px;
	}
  }
}
#tasks-panel {
  rev-task-search-form {
	.tasks-search-form {
	  padding: 10px 0;

	  .float-container:last-of-type {
		.pull-right {
		  float: none!important;

		  .btn:first-child {
			float: right;
		  }

		  .btn-primary {
			float: left;
			margin-right: 0.5em;
		  }
		}
	  }
	}
  }

  .task-buttons {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0;

	.pull-left, .pull-right {
	  display: flex;
	  flex-direction: row-reverse;
	  float: none!important;
	  margin-bottom: 0.25em;

	  .btn {
		margin-right: 0.5em;
		padding: 5px 8px;
		font-size: 12px;
		line-height: 1.5;
	  }
	}
	.pull-left {
	  order: 2;
	}
	.pull-right {
	  order: 1;
	  margin-right: 1em;
	}
  }
}

assign-task-directive {
  rev-employee-select {
	.multi-functional-select, .input-group {
	  margin-bottom: 0.5em;
	}
	.panel-body {
	  padding: 0.5em!important;
	}
	.employee-scroll-container {
	  height: 300px;
	}
	.rev-table .rev-table-footer .standard-footer {
	  .btn + .btn {
		margin-left: 0.5em;
	  }
	}
  }
}

.task-editor {
  .flow-container {
	margin-left: -5px;
	margin-right: -5px;
	> .flow-section {
	  margin-left: 0;
	  margin-right: 0;
	  margin-bottom: 10px;
	  padding-left: 5px;
	  padding-right: 5px;
	}
  }
  .task-details-section {
	width: 100%;
	order: 1;

	textarea {
	  height: 202px;
	}
  }
  .task-metadata-section {
	width: 100%;
	order: 3;
  }
  .task-assignee-section {
	width: 100%;
	order: 2;
  }
}
@media (min-width: $screen-sm-min) {
  .task-editor {
	.task-metadata-section {
	  width: 50%;
	}
	.task-assignee-section {
	  width: 50%;
	}
  }
}
@media (min-width: $screen-md-min) {
  .task-editor {
	.task-details-section {
	  width: 50%;
	}
  }
}
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .task-editor {
	.task-metadata-section {
	  .form-group {
		.col-sm-6 {
		  width: 75%;
		}
		label.col-sm-6 {
		  width: 25%;
		}
	  }
	}
  }
}
@media (min-width: $screen-lg-min) {
  .flow-container {
	flex-wrap: nowrap;
  }
  .task-editor {
	.task-metadata-section {
	  width: 25%;
	}
	.task-assignee-section {
	  width: 25%;
	}
  }
}

.rev-collapse-nav {
  h4 {
	margin: 0 0 10px;
  }
  > button {
	margin: 0 0 5px;
  }
}
.uib-datepicker {
  padding-left: 5px;
  padding-right: 5px;
}
.uib-datepicker,
.input-group-btn {
  .btn-default {
	background-color: $grey-white;
	&:hover,
	&:focus,
	&.active {
	  background-color: $state-default-bg;
	  color: $text-color;
	}
  }
}
.uib-datepicker {
  .btn-default {
	&.active {
	  background-color: $btn-primary-bg;
	  border-color: $btn-primary-border;
	  color: $grey-white;
	}
  }
}
.btn-toggle {
  .btn-primary {
	background-color: $brand-primary;
  }
  .btn-default:not(.btn-primary)[disabled] {
	background-color: $state-default-bg;
  }
}
rev-home-appointment-list-pod,
rev-home-messaging-pod,
rev-tasks-pod,
rev-patients-summary,
rev-patients-optical-summary {
  .btn-group.btn-tabs {
	margin: 5px 10px 0;
	padding: 0!important;
	display: block;
	width: auto;
	border-bottom: 1px solid $grey-dd;

	.btn {
	  background: none!important;
	  border: none;
	  border-bottom: 3px solid transparent;
	  border-radius: 0;
	  box-shadow: none!important;
	  color: $tab-text-color;
	  display: inline-block;
	  font-size: $tab-nrml-font-size;
	  padding: 4.5px 10px;
	  width: auto;

	  &:hover, &:focus {
		color: $tab-hover-text-color!important;
	  }

	  &.active {
		border-bottom-color: $tab-active-text-color;
		color: $tab-active-text-color!important;
	  }

	  .badge {
		background-color: $badge-bg!important;
	  }
	}
  }

  .nav-tools {
	> li:first-child > a {
	  background-color: $btn-success-bgcolor;
	  color: $btn-success-color;

	  &:hover, &:focus, &:active {
		background-color: $btn-success-hover-bgcolor;
	  }
	}
  }
  rev-rx-summary-pod,
  rev-account-summary-pod,
  rev-diagnosis-summary-pod,
  rev-recall-summary-pod,
  rev-task-summary-pod,
  rev-connect-summary-pod {
	.nav-tools {
	  > li:first-child > a {
		background-color: $btn-bgcolor;
		color: $btn-color;

		&:hover, &:focus, &:active {
		  background-color: $btn-hover-bgcolor;
		}
	  }
	}
  }
}
rev-patients-referrals,
rev-patients-immunizations {
  .nav-tools {
	> li:first-child > a {
	  background-color: $btn-success-bgcolor;
	  color: $btn-success-color;

	  &:hover, &:focus, &:active {
		background-color: $btn-success-hover-bgcolor;
	  }
	}
  }
}
.table thead tr.bg-default {
  background-color: $grey-white!important;
}
.multiselect {
  padding-right: 20px!important;
  .caret {
	color: $text-color;
	right: 8px;
  }
}
.rev-table  {
  #rev-table-scroll-container, .rev-table-footer {
	padding: 0!important;
  }
}

.pms-popout-window {
  background: none!important;
  padding: 20px;
  font-size: $font-size!important;
  table {
    font-size: $font-size!important;
    border: none;
  }
}

@media print {
	.pms-popout-window {
		padding: 0px!important;
	}
}
/* End Legacy App Style Overrides */
