/* ----------------------------------------------
   FOOTER
------------------------------------------------- */
#footer {
  background-color: $footerbar-bg;
  font-size:$font-size-smaller;
  color:$footerbar-text;
  padding: 10px 15px 0;
  overflow: hidden;
  text-align: center;
  z-index: 1029;
}
body.landing-screen #footer {
  background-color:$footerbar-alt-bg;
  color:$footerbar-alt-text;
}
body.landing-screen #footer a {
  color:$footerbar-alt-link;
}

.app-date-time {
  font-size: $font-size-smaller;

  .divider {
	padding:0 4px;
  }
}

@media (max-width: $screen-md-min) {
  #footer {
	position: static;
  }
  #footer .pull-left,
  #footer .pull-right {
	float: none!important;
  }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
  .landing-screen #footer {
	position: fixed;
  }
}
