/* ----------------------------------------------
   LAYOUT
------------------------------------------------- */
#wrapper {
  margin:50px 5px 45px;
}
.landing-screen #wrapper {
  margin-top: 50px;

  > .container-fluid {
	max-width: 1160px;
  }
}

.truncate {
  display: table;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
}
.truncate > * {
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
}

.display-table {
  display:table;
}
.display-table-cell {
  display:table-cell;
}

.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}

.live-chat {
  position:fixed;
  bottom:2px;
  right:3px;
  z-index:1050;

  &:not(.canvas-slid) {
	left: auto !important;
	right: 3px !important;
  }
}

.container,
.container-fluid {
  padding-left: 5px;
  padding-right: 5px;
}
.row {
  margin-left:-5px;
  margin-right:-5px;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-left:5px;
  padding-right:5px;
}

.rev-app-container {
  .rev-app-header-toolbar {
	background-color: $grey-f9;
	box-shadow: 0 2px 1px rgba(0,0,0,0.05);
	padding: 0 10px;
	margin: -10px -10px 10px;
	min-height: 38px;

	.rev-app-header-toolbar-tabs-container {
	  .e-tab {
		.e-tab-header {
		  margin: 0;
		  min-height: 38px!important;
		  height: auto!important;

		  &:not(.e-vertical)::before {
			border: none;
		  }

		  .e-toolbar-items, .e-toolbar-item {
			min-height: 38px!important;
			height: auto!important;

			.e-tab-wrap, .e-text-wrap {
			  height: 35px!important;
			}
		  }

		  .e-hor-nav, .e-hor-nav:not(.e-expended-nav) {
			margin-top: 3px;
		  }

		  .e-scroll-nav {
			min-height: 38px!important;
			height: 38px!important;
		  }
		}
	  }

	  .mat-tab-nav-bar {
		margin: 0;
		border: none;
		min-height: 38px;
		height: 38px;

		.mat-tab-link {
		  height: 38px;
		}
	  }
	}

	.rev-app-header-toolbar-action-container {
	  margin-left: 10px;
	  padding-top: 5px;
	}

	.rev-app-header-toolbar-title {
	  padding-top: 9px;

	  h4 {
		font-size: ceil(($font-size-base + 1));
	  }
	}

	.nav-tools {
	  margin: 0;
	  li {
		margin: 0 0 0 0.5em;

		a, button, .e-btn {
		  padding: 7.5px 10px;
		}
	  }
	}
  }
}

@media (min-width: $screen-sm) {
  #wrap {
	padding-right:210px;
  }
  #sidebar {
	float:left;
	margin-right:10px;
	width:200px;
  }
  #main {
	float:right;
	margin: 0 -210px 0 auto;
	width: 100%;
  }
}
