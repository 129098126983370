// Needed to load the fonts properly in the Angular CLI with scss.
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

// Colors
$brand-secondary: #52AADF;
$gray-base: #000;
$gray-dark: lighten($gray-base, 20%);
$gray: lighten($gray-base, 45%);
$gray-lighter: lighten($gray-base, 93.5%);
$gray-lightest: #f9f9f9;
$state-default-bg: #e9e9e9;
$state-info-bg: #e8ebf2;

// Typography
$body-bg: #e8ebf2;
$body-bg-alt: darken($brand-primary, 15%);
$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-base: 13px;
$font-size-smaller: ceil(($font-size-base - 1));
$font-size-h4: ceil(($font-size-base * 1.23));
$font-size-h5: ceil(($font-size-base * 1.05));
$text-color: #050505;
$link-color: #075FBE;
$link-hover-color: darken($link-color, 20%);
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 500;
$headings-color: $text-color;

// Navbar
$navbar-height: 40px;
$navbar-default-bg: $gray-lighter;
$navbar-default-link-color: #666;
$navbar-default-color: #666;
$navbar-inverse-color: darken($body-bg, 20%);
$navbar-inverse-bg: #0f2246;
$navbar-inverse-border: $navbar-inverse-bg;
$navbar-inverse-link-color: $navbar-inverse-color;
$navbar-inverse-link-hover-bg: $brand-primary;
$navbar-inverse-link-active-color: #fff;
$navbar-inverse-link-active-bg: $brand-primary;
$navbar-inverse-alt-color: lighten($navbar-inverse-color, 15%);
$navbar-inverse-toggle-hover-bg: $navbar-inverse-bg;
$navbar-inverse-toggle-border-color: $navbar-inverse-bg;

// Navmenu
$navmenu-width: 280px;
$navmenu-inverse-bg: darken($navbar-inverse-bg, 12%);
$navmenu-inverse-link-color: #fff;
$navmenu-inverse-link-hover-bg: $navbar-inverse-bg;
$navmenu-inverse-link-active-bg: $navbar-inverse-bg;

// Footer
$footerbar-bg: darken($body-bg, 8%);
$footerbar-text: darken($brand-primary, 20%);
$footerbar-alt-bg: $brand-primary;
$footerbar-alt-text: lighten($brand-primary, 40%);
$footerbar-alt-link: #fff;

// Navs
$nav-link-padding: 5px 10px;
$nav-tabs-active-link-hover-bg: #fff;
$nav-tabs-active-link-hover-color: $gray-dark;
$nav-tabs-justified-active-link-border-color: #fff;

// Buttons
$btn-default-color: $text-color;
$btn-default-bg: #ccc;
$btn-default-border: $btn-default-bg;

// Pagination
$pagination-active-color: #333;
$pagination-active-bg: #ddd;
$pagination-active-border: #ccc;

// Panels
$panel-bg: $grey-f9;
$panel-body-padding: 10px;
$panel-heading-padding: $panel-body-padding;
$panel-footer-padding: $panel-body-padding;
$panel-default-text: $headings-color;
$panel-default-heading-bg: $panel-bg;
$panel-footer-bg: $panel-default-heading-bg;
$panel-info-heading-bg: lighten($brand-secondary, 34%);

// Tables
$table-bg: #fff;
$table-border-color: #ccc;
$table-bg-hover: lighten($state-info-bg, 5%);
$table-bg-active: $state-info-bg;

// Forms
$input-color: $gray-dark;
$form-group-margin-bottom: 7px;

// Components
$padding-base-horizontal: 10px;
$padding-small-horizontal: 8px;
$padding-xs-vertical: 4px;
$padding-xs-horizontal: 6px;
$component-active-bg: #ddd;
$component-active-color: #333;
$border-radius-base: 4px;
$border-radius-small: 4px;
$list-group-link-color: $text-color;
$list-group-bg: $gray-lightest;
$list-group-hover-bg: $state-default-bg;
$list-group-active-color: $text-color;
$list-group-active-bg: $state-default-bg;
$list-group-active-border: #ddd;
$badge-bg: $grey-light;

// Modals
$modal-lg: 1000px;
$modal-inner-padding: 10px;
$modal-title-padding: 10px;
$modal-content-bg: $grey-f9;

// Alerts
$alert-padding: 10px;

// Grid
$grid-gutter-width: 20px;

$thumbnail-bg: #fff;

// Textarea row height
$textarea-row-height: 19px;

// SyncFusion
$brand-primary:#428bca !default;
$brand-primary-font:#fff !default;
$brand-primary-darken-10: #3071a9 !default;
$brand-primary-darken-15: #2a6496 !default;
$brand-primary-darken-25: #1f496e !default;
$brand-primary-darken-35: #142f46 !default;
$grey-base: #000 !default;
$grey-darker: #222 !default;
$grey-dark: #333 !default;
$grey: #555 !default;
$grey-light: #777 !default;
$grey-44: #444 !default;
$grey-88: #888 !default;
$grey-99: #999 !default;
$grey-8c: #8c8c8c !default;
$grey-ad: #adadad !default;
$grey-dark-font: #fff !default;
$grey-white: #fff !default;
$grey-lighter: #eee !default;
$grey-f9: #f9f9f9 !default;
$grey-f8: #f8f8f8 !default;
$grey-f5: #f5f5f5 !default;
$grey-e6: #e6e6e6 !default;
$grey-dd: #ddd !default;
$grey-d4: #d4d4d4 !default;
$grey-cc: #ccc !default;
$grey-light-font: #333 !default;
$brand-success: #8FA73C !default;
$brand-success-dark: #3c763d !default;
$brand-info: #5bc0de !default;
$brand-info-dark: #31708f !default;
$brand-warning: #f49b01 !default;
$brand-warning-dark: #8a6d3b !default;
$brand-danger: #d9534f !default;
$brand-danger-dark: #a94442 !default;
$brand-success-light: #dff0d8 !default;
$brand-info-light: #d9edf7 !default;
$brand-warning-light: #fcf8e3 !default;
$brand-danger-light: #f2dede !default;
$input-border-focus: #66afe9 !default;
$brand-success-font: #3c763d !default;
$brand-info-font: #31708f !default;
$brand-warning-font: #8a6d3b !default;
$brand-danger-font: #a94442 !default;
$base-font: #000 !default;
$shadow: #000 !default;

$tooltip-content-bg-color: #fff;
$tooltip-content-border-color: rgba(0,8,16,.15);
$tooltip-content-font-color: $text-color;

// UIB Tooltip
$tooltip-bg: #fff;
$tooltip-color: $text-color;
$tooltip-arrow-color: #fff;
